// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-datenschutz-tsx": () => import("./../../../src/pages/datenschutz.tsx" /* webpackChunkName: "component---src-pages-datenschutz-tsx" */),
  "component---src-pages-fachbereiche-tsx": () => import("./../../../src/pages/fachbereiche.tsx" /* webpackChunkName: "component---src-pages-fachbereiche-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-kontakt-tsx": () => import("./../../../src/pages/kontakt.tsx" /* webpackChunkName: "component---src-pages-kontakt-tsx" */),
  "component---src-pages-stellen-tsx": () => import("./../../../src/pages/stellen.tsx" /* webpackChunkName: "component---src-pages-stellen-tsx" */),
  "component---src-pages-ueberuns-die-niederegger-ag-tsx": () => import("./../../../src/pages/ueberuns/die-niederegger-ag.tsx" /* webpackChunkName: "component---src-pages-ueberuns-die-niederegger-ag-tsx" */),
  "component---src-pages-ueberuns-leitbild-tsx": () => import("./../../../src/pages/ueberuns/leitbild.tsx" /* webpackChunkName: "component---src-pages-ueberuns-leitbild-tsx" */),
  "component---src-pages-ueberuns-team-tsx": () => import("./../../../src/pages/ueberuns/team.tsx" /* webpackChunkName: "component---src-pages-ueberuns-team-tsx" */),
  "component---src-templates-archiv-tsx": () => import("./../../../src/templates/archiv.tsx" /* webpackChunkName: "component---src-templates-archiv-tsx" */),
  "component---src-templates-fachbereich-tsx": () => import("./../../../src/templates/fachbereich.tsx" /* webpackChunkName: "component---src-templates-fachbereich-tsx" */),
  "component---src-templates-projekt-tsx": () => import("./../../../src/templates/projekt.tsx" /* webpackChunkName: "component---src-templates-projekt-tsx" */)
}

